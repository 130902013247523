<template>
  <div class="map">
    <div class="map__loading" v-if="loading">
      <span class="loader"></span>
    </div>
    <div class="map__table">
      <div class="map__table-filters">
        <div class="map__table-buttons">
          <div class="map__table-button">
            <a
              href="javascript:void(0)"
              @click="setCity('moscow')"
              :class="{ active: city === 'moscow' }"
              >Москва</a
            >
          </div>
          <div class="map__table-button">
            <a
              href="javascript:void(0)"
              @click="setCity('spb')"
              :class="{ active: city === 'spb' }"
              >Санкт-Петербург</a
            >
          </div>
        </div>
        <div class="map__table-select">
          <div class="select">
            <div
              class="select__el select__el--main"
              @click="opened = !opened"
              :class="{ opened: opened }"
            >
              <div class="select__title">
                <strong>{{ currency }}</strong>
                <p v-if="currency == 'USD'">Доллар США</p>
                <p v-if="currency == 'EUR'">Евро</p>
              </div>
              <div class="select__arrow"></div>
            </div>
            <div class="select__dropdown" v-if="opened">
              <div class="select__el" v-if="currency !== 'USD'" @click="currency = 'USD'">
                <div class="select__title">
                  <strong>USD</strong>
                  <p>Доллар США</p>
                </div>
              </div>
              <div class="select__el" v-if="currency !== 'EUR'" @click="currency = 'EUR'">
                <div class="select__title">
                  <strong>EUR</strong>
                  <p>Евро</p>
                </div>
              </div>
              <div class="select__el" v-if="currency !== 'CNY'" @click="currency = 'CNY'">
                <div class="select__title">
                  <strong>CNY</strong>
                  <p>Китайский юань</p>
                </div>
              </div>
              <div class="select__el" v-if="currency !== 'AED'" @click="currency = 'AED'">
                <div class="select__title">
                  <strong>AED</strong>
                  <p>Дирхам ОАЭ</p>
                </div>
              </div>
              <div class="select__el" v-if="currency !== 'THB'" @click="currency = 'THB'">
                <div class="select__title">
                  <strong>THB</strong>
                  <p>Тайский бат</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map__table-content">
        <div class="table" v-if="city === 'moscow'">
          <table>
            <thead>
              <th>Банк</th>
              <th>Купить</th>
              <th>Продать</th>
            </thead>
            <tr
              @click="openPoint(item, index)"
              v-for="(item, index) in list.filter(branch => currency in branch.symbols)"
              :key="index"
            >
              <td>{{ item.branch.address }}</td>
              <td>{{ item.symbols[currency].offer }} ₽</td>
              <td>{{ item.symbols[currency].bid }} ₽</td>
            </tr>
          </table>
        </div>
        <div class="table" v-if="city === 'spb'">
          <table>
            <thead>
              <th>Банк</th>
              <th>Купить</th>
              <th>Продать</th>
            </thead>
            <tr
              @click="openPoint(item, index)"
              v-for="(item, index) in list.filter(branch => currency in branch.symbols)"
              :key="index"
            >
              <td>{{ item.branch.address }}</td>
              <td>{{ item.symbols[currency].offer }} ₽</td>
              <td>{{ item.symbols[currency].bid }} ₽</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="map__table-footer">
        <div class="map__table-contacts">Контактный центр: <a href="tel:+78005555550" @click="sendEvent">+7 800 555-55-50</a></div>
        <div class="map__table-date">Обновление: {{ updated }}</div>
      </div>
    </div>
    <div class="map__el">
      <div id="map" v-if="reloaded"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
let map;
export default {
  name: "MapBlock",
  data() {
    return {
      map: null,
      clusterer: null,
      currency: "USD",
      placemarks: [],
      opened: false,
      list: [],
      updated: null,
      loading: true,
      reloaded: true,
      city: 'moscow',
      settings: [],
    }
  },
  async mounted() {
    await this.getSettings();
    await this.getList();
    const script = document.createElement("script");
    script.src =
      `https://api-maps.yandex.ru/2.1/?apikey=${this.settings.find(s => s.name = 'yandex_key')?.value}&lang=ru_RU`;
    script.type = "text/javascript";
    script.onload = this.initMap;
    document.head.appendChild(script);
    document.addEventListener("click", this.closeDropdown);
  },
  watch: {
    currency() {
      this.reloaded = false;
      this.opened = false;
      setTimeout(() => {
        this.reloaded = true;
        this.initMap();
      }, 100)
    },
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    sendEvent() {
      if (window.ym) {
        window.ym(98650851,'reachGoal','click_phone')
      }
    },
    async getSettings() {
      let list = await axios.get(`https://sber-terminator-api.dev-sberseller.ru/api/settings/`);
      this.settings = list.data;
    },
    async getList() {
      let list = await axios.get(`https://sber-terminator-api.dev-sberseller.ru/api/branches/?city=${this.city}`);
      this.list = list.data.data;
      this.updated = list.data.updated;
      this.loading = false;
    },
    closeDropdown(event) {
      if (
        !event.target.classList.contains("select") &&
        !event.target.closest(".select")
      ) {
        this.opened = false;
      }
    },
    openPoint(item, index) {
      map.setCenter(item.branch.point.split(","), 16, {
        checkZoomRange: true,
      });
      setTimeout(() => {
        const contentElement = document.getElementById(`placemark-${index}`);
        if (contentElement) {
          contentElement.classList.add("map__content--open");
        }
      }, 100)
    },
    async setCity(city) {
      this.city = city;
      this.loading = true;
      this.reloaded = false;
      await this.getList();
      this.reloaded = true;
      this.initMap();
      if (city == "moscow") {
        map.setCenter([55.755864, 37.617698], 10, {
          checkZoomRange: true,
        });
      }
      if (city == "spb") {
        map.setCenter([59.938784, 30.314997], 10, {
          checkZoomRange: true,
        });
      }
    },
    initMap() {
      window.ymaps.ready(() => {
        let coords = [55.755864, 37.617698];
        if (this.city == "spb") {
          coords = [59.938784, 30.314997];
        }
        map = new window.ymaps.Map("map", {
          center: coords,
          zoom: 10,
        });

        let clusterer = new window.ymaps.Clusterer({
          preset: "islands#darkGreenClusterIcons",
          groupByCoordinates: false,
          openBalloonOnClick: false,
          clusterIcons: [
            {
              href: "/cluster.svg",
              size: [40, 40],
              offset: [-20, -20],
            },
          ],
        });

        let placemarks = [];
        setTimeout(() => {
          this.list.filter(branch => this.currency in branch.symbols).forEach((point, index) => {
            let bid = point.symbols[this.currency].bid;
            let offer = point.symbols[this.currency].offer;
            var placemark = new window.ymaps.Placemark(
              point.branch.point.split(","),
              {},
              {
                iconLayout: "default#imageWithContent",
                iconImageHref: "/marker.svg",
                iconImageSize: [40, 40],
                iconImageOffset: [-20, -20],
                iconContentLayout: window.ymaps.templateLayoutFactory.createClass(
                  `<div class="map__content" id="placemark-${index}">` +
                    `<div class="map__content-title">1 ${this.currency}</div>` +
                    `<div class="map__content-subtitle">Продать: ${bid} р</div>` +
                    `<div class="map__content-subtitle">Купить: ${offer} р</div>` +
                    `<div class="map__content-subtitle map__content-subtitle--hidden">${point.branch.address}</div>` +
                    "</div>"
                ),
              }
            );
            placemark.events.add("click", () => {
              //добавляем класс к метке
              const contentElement = document.getElementById(`placemark-${index}`);
              if (contentElement) {
                contentElement.classList.toggle("map__content--open");
              }
            });

            placemarks.push(placemark);
          });
          clusterer.add(placemarks);
          map.geoObjects.add(clusterer);
        }, 100);
        setTimeout(() => {
          placemarks.forEach((placemark) => {
            let parent = placemark.getParent();
            if (parent !== null) {
              // placemark.balloon.open();
            }
          });
          this.placemarks = placemarks;
        }, 150);
      });
    },
  },
};
</script>

<style lang="sass">
#map
  width: 100%
  height: 100%
.map
    margin-top: vw(28px)
    background-color: #fff
    border-radius: vw(12px)
    border: 1px solid #C5C5C5
    display: flex
    overflow: hidden
    position: relative
    margin-bottom: vw(117px)
    @media screen and (max-width: 1000px)
      margin-top: vwm(19px)
      margin-bottom: vwm(100px)
      border-radius: vwm(16px)
      flex-direction: column
    &__loading
      width: 100%
      height: 100%
      background-color: rgba(255,255,255,0.3)
      backdrop-filter: blur(3px)
      position: absolute
      top: 0
      left: 0
      z-index: 100
      display: flex
      align-items: center
      justify-content: center
    &__content
      margin-left: 45px
      background-color: rgba(255,255,255,0.5)
      border-radius: 10px
      padding: 8px
      width: 120px
      margin-top: -15px
      line-height: 1.4
      text-align: left
      backdrop-filter: blur(5px)
      &-title
        font-size: 14px
        font-weight: 500
        color: #54974A
      &-text
        font-size: 14px
        font-weight: 400
        color: #000000
      &-subtitle
        font-size: 13px
        font-weight: 400
        color: #000000
        &--hidden
          font-size: 11px
          margin-top: 10px

    &__el
      width: vw(546px)
      height: vw(453px)
      @media screen and (max-width: 1000px)
        width: vwm(324px)
        height: vwm(336px)
        border-radius: vwm(17.03px)
        border: 1px solid #C5C5C5
        margin: auto
        margin-top: vwm(11px)
        margin-bottom: vwm(15px)
        overflow: hidden
    &__table
        padding-left: vw(64px)
        padding-top: vw(22.5px)
        padding-right: vw(57px)
        padding-bottom: vw(18px)
        width: vw(579px)
        @media screen and (max-width: 1000px)
          width: 100%
          padding-left: vwm(25px)
          padding-top: vwm(20px)
          padding-right: vwm(20px)
        &-footer
            display: flex
            justify-content: space-between
            padding-top: vw(8.75px)
            font-size: vw(9px)
            color: #000
            @media screen and (max-width: 1000px)
              font-size: vwm(10px)
              padding-top: vwm(11px)
        &-contacts
          width: 40%
          line-height: 1.2
          a
            color: #000
            text-decoration: none
          @media screen and (max-width: 1000px)
            width: 34%
        &-date
            opacity: 0.5
            margin-right: vw(19px)
        &-select
          @media screen and (max-width: 1000px)
            width: 100%
            margin-top: vwm(11px)
        &-buttons
            display: flex
            @media screen and (max-width: 1000px)
              width: 100%
              justify-content: space-between
            a
                display: block
                padding: vw(4.37px) vw(19.5px)
                font-size: vw(10px)
                text-decoration: none
                color: #000
                margin-right: vw(7.5px)
                border: 1px solid #C5C5C5
                border-radius: vw(2.25px)
                @media screen and (max-width: 1000px)
                  font-size: vwm(13.32px)
                  padding: vwm(6px) vwm(19px)
                  min-width: vwm(138px)
                  text-align: center
                  margin-right: 0
                &.active
                    background-color: #21BA72
                    border-color: #21BA72
                    color: #fff
        &-filters
            display: flex
            justify-content: space-between
            width: 100%
            padding-right: vw(20px)
            @media screen and (max-width: 1000px)
              flex-wrap: wrap
              padding-right: 0
.select
    display: flex
    position: relative
    z-index: 10
    @media screen and (max-width: 1000px)
      width: 100%
    &__dropdown
      position: absolute
      z-index: 1
      top: vw(21px)
      width: 100%
      border-top: 0
      @media screen and (max-width: 1000px)
        top: vwm(29px)
      .select__el
        border-top: 0
        border-top-right-radius: 0
        border-top-left-radius: 0
    &__el
        display: flex
        border: 1px solid #C5C5C5
        border-radius: vw(4.5px)
        height: vw(21.75px)
        min-width: vw(140px)
        display: flex
        align-items: center
        cursor: pointer
        background-color: #fff
        @media screen and (max-width: 1000px)
          height: vwm(29px)
          width: 100%
          min-width: 100%
        &.opened
          border-bottom: 1px solid transparent
          border-bottom-right-radius: 0
          border-bottom-left-radius: 0
          .select__arrow
            transform: rotate(180deg) !important
    &__arrow
        display: block
        width: vw(10px)
        height: vw(10px)
        background-image: url('@/assets/img/arrow.svg')
        background-size: contain
        background-repeat: no-repeat
        margin-right: vw(9px)
        margin-left: auto
        transition: all 300ms
        @media screen and (max-width: 1000px)
          width: vwm(13px)
          height: vwm(13px)
          margin-right: vwm(10px)
    &__title
        display: flex
        font-size: vw(10px)
        padding-left: vw(7px)
        @media screen and (max-width: 1000px)
          font-size: vwm(14px)
          padding-left: vwm(6px)
        strong
            font-weight: 500
            padding-right: vw(7px)
            @media screen and (max-width: 1000px)
              padding-right: vwm(8px)
.table
    margin-top: vw(22px)
    height: vw(349px)
    overflow-y: scroll
    &::-webkit-scrollbar
      -webkit-appearance: none
    &::-webkit-scrollbar:vertical
      width: 12px
    &::-webkit-scrollbar:horizontal
      height: 12px
    &::-webkit-scrollbar-thumb
      background-color: #fff
      border-radius: 10px
      border: 2px solid #F2F2F2
    &::-webkit-scrollbar-track
      border-radius: 10px
      background-color: #F2F2F2
    @media screen and (max-width: 1000px)
      width: 100%
      height: auto
      position: relative
      max-height: vwm(156px)
      table
        width: 100%
    th
        padding: vw(5.5px) vw(15px)
        text-align: left
        font-size: vw(10.5px)
        border-bottom: 1px solid #C5C5C5
        @media screen and (max-width: 1000px)
          padding: vwm(5.5px) vwm(5px)
          font-size: vwm(10.5px)
    tr
        cursor: pointer
        &:hover
            td
                background-color: #EEF3FF
    td
        padding: vw(14.8px) vw(15px)
        text-align: left
        font-size: vw(10.5px)
        background-color: #fff
        transition: all 300ms
        font-weight: 500
        border-bottom: 1px solid #EEEEEE
        @media screen and (max-width: 1000px)
          padding: vwm(13px) vwm(5px)
          font-size: vwm(10px)
        &:nth-child(1)
            width: vw(256px)
            line-height: 1.2
            @media screen and (max-width: 1000px)
              width: vwm(177px)
        &:nth-child(2)
            width: vw(90px)
            @media screen and (max-width: 1000px)
              width: vwm(62px)
        &:nth-child(3)
            width: vw(88px)
            @media screen and (max-width: 1000px)
              width: vwm(62px)
.map__content-subtitle--hidden
  display: none
.map__content--open
  .map__content-subtitle--hidden
    display: block !important
</style>

<style>
.loader {
  width: 48px;
  height: 48px;
  display: block;
  margin: 20px auto;
  box-sizing: border-box;
  position: relative;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  left: 0;
  bottom: 0;
  position: absolute;
  border-radius: 50% 50% 0;
  border: 15px solid #21BA72;
  transform: rotate(45deg) translate(0, 0);
  box-sizing: border-box;
  animation: animMarker 0.4s ease-in-out infinite alternate;
}
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 150%;
  width: 24px;
  height: 4px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  animation: animShadow 0.4s ease-in-out infinite alternate;
}

@keyframes animMarker {
  0% {
    transform: rotate(45deg) translate(5px, 5px);
  }
  100% {
    transform: rotate(45deg) translate(-5px, -5px);
  }
}

@keyframes animShadow {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
    
</style>