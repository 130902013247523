import { createApp } from 'vue'
import { createYmaps } from 'vue-yandex-maps'
import App from './App.vue'
import '@/assets/css/main.css'

const app = createApp(App)

app.use(createYmaps({
    apikey: '6aa02c5f-c677-4c90-b0f7-f861b7d3e0b9',
}));

app.mount('#app')