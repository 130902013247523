<template>
    <div class="banner">
        <div class="banner__title">Валюта для любых целей</div>
        <div class="banner__text">Обменивайте валюты в офисах СберБанка <br>для поездок <span></span>за границу и личных сбережений</div>
        <div class="banner__image"><img src="@/assets/img/balloons.png" alt=""></div>
    </div>
</template>

<script>
    export default {
        name: 'BannerBlock',
    }
</script>

<style lang="sass">
.banner
    width: 100%
    min-height: vw(264px)
    background-image: url('@/assets/img/banner.jpg')
    background-size: cover
    background-repeat: no-repeat
    background-position: 50% 50%
    margin-top: vw(48px)
    border: 1px solid #C5C5C5
    border-radius: vw(12px)
    padding-top: vw(70px)
    padding-bottom: vw(66px)
    padding-left: vw(57px)
    position: relative
    overflow: hidden
    @media screen and (max-width: 1000px)
        border-radius: vwm(12px)
        padding-top: vwm(23px)
        height: vwm(157px)
        margin-top: vwm(23px)
        padding-bottom: vwm(66px)
        padding-left: vwm(19px)
    &__title
        font-size: vw(48px)
        font-weight: 500
        letter-spacing: vw(-0.22px)
        @media screen and (max-width: 1000px)
            font-size: vwm(28px)
    &__text
        font-size: vw(24px)
        line-height: 1.2
        font-weight: 500
        margin-top: vw(22px)
        letter-spacing: vw(-0.18px)
        @media screen and (max-width: 1000px)
            font-size: vwm(12px)
            margin-top: vwm(15px)
            padding-right: vwm(130px)
            span
                display: block
            br
                display: none
    &__image
        width: vw(262px)
        height: vw(328px)
        position: absolute
        right: vw(103px)
        top: vw(-45px)
        @media screen and (max-width: 1000px)
            width: vwm(121px)
            height: vwm(153px)
            top: vwm(20px)
            right: vwm(6px)
        img
            width: 100%
            height: 100%
</style>
