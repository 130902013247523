<template>
    <div class="logo">
        <a href="javascript:void(0)"></a>
    </div>
</template>

<script>
    export default {
        name: 'LogoBlock',
    }
</script>

<style lang="sass">
.logo
  background-image: url('@/assets/img/logo.svg')
  width: vw(147px)
  height: vw(24px)
  background-size: contain
  background-repeat: no-repeat
  display: block
  margin-left: vw(39px)
  margin-top: vw(37px)
  position: relative
  @media screen and (max-width: 1000px)
    width: vwm(138px)
    height: vwm(22px)
    margin-top: vwm(18px)
    margin-left: auto
    margin-right: auto
</style>
