<template>
    <div class="page">
        <Logo></Logo>
        <div class="container">
            <Banner></Banner>
            <div class="title">Карта обменных <span></span>пунктов <br>СберБанка</div>
            <Map></Map>
        </div>
        <div class="erid">Erid: 2RanymzVDVA Реклама. ПАО Сбербанк. Генеральная лицензия Банка России № 1481 от 11.08.2015</div>
    </div>
</template>

<script>
import Logo from './components/Logo.vue'
import Banner from './components/Banner.vue'
import Map from './components/Map.vue'
export default {
    name: 'app',
    components: {
        Logo,
        Banner,
        Map
    },
}
</script>

<style lang="sass">
.page
    position: relative
    display: flex
    flex-direction: column
.container
    width: vw(1125px)
    margin-left: auto
    margin-right: auto
    @media screen and (max-width: 1000px)
        width: vwm(351px)
.title
    font-size: vw(36px)
    font-weight: 500
    line-height: 1.2
    letter-spacing: vw(-0.2px)
    padding-left: vw(58px)
    padding-top: vw(48px)
    @media screen and (max-width: 1000px)
        font-size: vwm(24px)
        padding-left: 0
        padding-top: vwm(23px)
        text-align: center
        span
            display: block
        br
            display: none
.erid
    padding-left: vw(61px)
    margin-top: vw(-20px)
    padding-bottom: vw(40px)
    color: #000
    opacity: 0.5
    font-size: vw(12px)
    @media screen and (max-width: 1000px)
        font-size: vwm(10px)
        line-height: 1.2
        padding-left: vwm(12px)
        padding-right: vwm(12px)
        margin-top: vwm(-60px)
        padding-bottom: vwm(20px)
</style>
